<template>
  <div>
    <el-page-header :content="'设备文件轨迹'" @back="goBack" style="margin-bottom:10px"></el-page-header>
    <el-card class="box-card">
      <template #header>
        <div class="card-header">
          <el-form :inline="true" class="demo-form-inline">
            <div style="margin-bottom: 20px">需要转换的GPS数据</div>
            <el-form-item :label="$t('经度')">
              <el-input v-model="inputLongitude" clearable placeholder="请输入GPS经度数据"/>
            </el-form-item>
            <el-form-item :label="$t('纬度')">
              <el-input v-model="inputLatitude" clearable placeholder="请输入GPS纬度数据"/>
            </el-form-item>
            <el-form-item>
              <el-button @click="loadData" type="primary">{{ $t('device.inquire') }}</el-button>
            </el-form-item>
          </el-form>
          <el-form :inline="true" class="demo-form-inline">
            <div style="margin-bottom: 20px">直接显示的GPS数据</div>
            <el-form-item :label="$t('经度')">
              <el-input v-model="inputLongitude2" clearable placeholder="请输入GPS经度数据"/>
            </el-form-item>
            <el-form-item :label="$t('纬度')">
              <el-input v-model="inputLatitude2" clearable placeholder="请输入GPS纬度数据"/>
            </el-form-item>
            <el-form-item>
              <el-button @click="handleShow" type="primary">{{ $t('device.inquire') }}</el-button>
            </el-form-item>
          </el-form>
          <el-form-item :label="$t('上传文件')">
            <el-upload
                ref="upload"
                action="/common/location_file"
                :limit="1"
                :on-success="handleExcelSuccess"
            >
              <template #trigger>
                <el-button type="primary">选择文件</el-button>
              </template>
              <template #tip>
                <div class="el-upload__tip text-red">
                  只支持同时上传一个文件
                </div>
              </template>
            </el-upload>
          </el-form-item>
        </div>
      </template>
      <div class="mapcont">
        <div id="container" style="width:100%;min-height:600px;"></div>
        <div
            class="panelist"
            style="position: fixed;width:280px;min-height:600px;height:700px;top:15%;right:10px;overflow-y:auto;"
        ></div>
      </div>
    </el-card>
  </div>
</template>
<script>
import AMapLoader from "@amap/amap-jsapi-loader";
import data from "../../model/data";

export default {
  name: "dataTrack",
  data() {
    return {
      imei: this.$route.query.imei,
      location: [],
      inputLongitude: "",
      inputLatitude: "",
      inputLongitude2: "",
      inputLatitude2: "",
      longitude: "",
      latitude: "",
      start_time: "",
      end_time: "",
      date: "",
      disabledDate(time) {
        return time.getTime() > Date.now();
      }
    };
  },
  mounted() {
  },
  methods: {
    async loadData() {
      const ret = await data.transform({
        longitude: this.inputLongitude,
        latitude: this.inputLatitude
      });
      if (ret.code === 200) {
        this.longitude = ret.data.longitude;
        this.latitude = ret.data.latitude;
        this.showMap2();
      } else {
        this.$message.warning(ret.message);
      }
    },
    handleShow(){
      this.longitude = this.inputLongitude2
      this.latitude = this.inputLatitude2
      this.showMap2()
    },
    handleExcelSuccess(response) {
      this.location = response.data
      this.showMap()
      // if (response.code === 200) {
      // } else {
      //   this.$message.error(response.message);
      // }
    },
    async showMap() {
      document.getElementsByClassName("panelist")[0].innerHTML = "";
      const AMap = await AMapLoader.load({
        key: this.$store.state.amap_key, // 申请好的Web端开发者Key，首次调用 load 时必填
        version: this.$store.state.amap_version, // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [] //插件列表
      })
      const option = {
        resizeEnable: true,
        zoom: 15,
        zoomToAccuracy: true,
        center: [this.location[0].longitude, this.location[0].latitude]
      };
      this.map = new AMap.Map("container", option);
      let chartData = [];
      this.location.forEach(value => {
        chartData.push([value.longitude, value.latitude]);
        const marker = new AMap.Marker({
          icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
          position: [value.longitude, value.latitude],
          title: ''
        });
        // 将以上覆盖物添加到地图上
        // 单独将点标记添加到地图上
        this.map.add(marker);
      });
      let len = chartData.length;
      for (let i = 0; i < len - 1; i++) {
        let startPoint = chartData[i];
        let endPoint = chartData[i + 1];

        var divhtml =
            "<b>" +
            '暂无时间' +
            " — " +
            '暂无时间' +
            '</b><div class="panel" id="panel_' +
            i +
            '"></div>';
        document.getElementsByClassName("panelist")[0].innerHTML += divhtml;

        let option = {
          map: this.map,
          panel: "panel_" + i,
          hideMarkers: true
        };
        this.map.plugin("AMap.Walking", () => {
          // 步行导航
          let walking = new AMap.Walking(option);
          // eslint-disable-next-line no-unused-vars
          walking.search(startPoint, endPoint);
        });
      }
    },
    async showMap2() {
      document.getElementsByClassName("panelist")[0].innerHTML = "";
      const AMap = await AMapLoader.load({
        key: this.$store.state.amap_key, // 申请好的Web端开发者Key，首次调用 load 时必填
        version: this.$store.state.amap_version, // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [] //插件列表
      })
      const option = {
        resizeEnable: true,
        zoom: 15,
        zoomToAccuracy: true,
        center: [this.longitude, this.latitude]
      };
      this.map = new AMap.Map("container", option);
      // let chartData = [];
      const marker = new AMap.Marker({
        icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
        position: [this.longitude, this.latitude],
        title: ''
      });
      // 将以上覆盖物添加到地图上
      // 单独将点标记添加到地图上
      this.map.add(marker);
    },
    goBack() {
      this.$router.replace({
        path: "/data/home"
      })
    }
  }
};
</script>
<style scoped>
</style>
